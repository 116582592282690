<template>
  <el-dialog
    :title="title"
    :visible.sync="clientVisible"
    width="540px"
    @close="reset"
    :close-on-click-modal="false"
  >
    <div class="client-manager-add" v-if="clientVisible">
      <div class="client-add-main">
        <div style="width: 100%">
          <els-form ref="elsf" :column="column" v-model="form" :ctx="{lang: $t('d')}"></els-form>
          <div class="client-footer">
            <el-button @click="reset">{{$t('d.cancel')}}</el-button>
            <el-button type="primary" @click="handleClick" :loading="isSure"
              >{{$t('d.confirm')}}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import elsForm from "../../../../components/els-form/main.vue";
import { addDeviceType } from "@/api/manager/client-type-manage.js";
import mixinsI18n from "@/mixins/i18n.js";
export default {
  name: "add-client",
  mixins: [mixinsI18n],
  components: { elsForm },
  props: {
    title: String,
    column: Array,
  },
  data: () => {
    return {
      clientVisible: false,
      dicts: {
        networkingMode: [],
        protocol: [],
        accessType: [],
        dataType: [],
      },
      form: {},
      activeName: "1",
      isSure: false,
      PAGE_NAME: "clientTypePage",
    };
  },
  watch: {
    clientVisible(v) {
      if (v) {
        this.form = {};
      }
    },
  },
  methods: {
    async handleClick() {
      this.isSure = true;
      const app = this.$refs.elsf;
      try {
        app && (await app.validate());
        let data = this.form;
        if(data.selfTag){
          data.tagLib = JSON.stringify(data.selfTag);
        }
        if(data.taglib){
          data.taglib = JSON.stringify(data.taglib);
        }
        data.featureSet = "";

        await addDeviceType({ ...data })
          .then((res) => {
            if (res) {
              this.isSure = false;
              this.$message({
                message: this.lang.saveSuccess,
                type: "success",
              });
              this.$emit("addOk");
              this.clientVisible = false;
            }
          })
          .catch((err) => (this.isSure = false));
      } catch (e) {
        setTimeout(() => {
          this.isSure = false;
        }, 100);
        console.log(e);
      }
    },
    reset() {
      this.clientVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 0px;
}
::v-deep .el-select {
  width: 100%;
}
.t--footer {
  display: none !important;
  margin-left: 0px;
}
.client-manager-add {
  margin: 20px;
  .client-add-main {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    .label-name {
      margin-bottom: 10px;
    }
    .client-footer {
      text-align: right;
      margin: 10px 0px;
    }
  }
}
</style>
