import http from "@/axios/index";
import { URI } from "@/config/axios";

// POST /api/deviceType
export const addDeviceType = (data) => {
  return http({
    url: URI.system + "api/deviceType",
    method: "post",
    data,
  });
};

// DELETE /api/DeviceType/{deviceId}
export const deleteDeviceType = (id) => {
  return http({
    url: URI.system + "api/DeviceType/" + id,
    method: "delete",
    params: { deviceTypeId: id },
  });
};

// GET /api/getDeviceType/{deviceTypeId}
export const getDeviceTypeById = (id) => {
  return http({
    url: URI.system + "api/getDeviceType/" + id,
    method: "get",
    params: { deviceTypeId: id },
  });
};

// GET /api/tenant/deviceTypes{?typeName,textSearch,idOffset,textOffset,limit}
export const getDeviceTypes = (params) => {
  return http({
    url: URI.system + "api/tenant/deviceTypes",
    method: "get",
    params,
  });
};

// DELETE /api/tenant/mqttTopic
export const deleteMqttTopic = (d) => {
  return http({
    url: URI.system + "api/tenant/mqttTopic/" + d.id,
    method: "delete",
    // params
  });
};

// POST /api/tenant/mqttTopic
export const addMqttTopic = (data) => {
  return http({
    url: URI.system + "api/tenant/mqttTopic",
    method: "post",
    data,
  });
};

// GET /api/tenant/mqttTopics{?deviceTypeId}
export const getMqttTopics = (params) => {
  return http({
    url: URI.system + "api/tenant/mqttTopics",
    method: "get",
    params,
  });
};

// device-type-controller
export const getFeatureSet = (d = {}) => {
  return http({
    url: URI.system + "/api/tenant/featureSet",
    method: "get",
    params: d,
  });
};
// 保存自定义解析
export const saveFeatureSet = (d = {}) => {
  return http({
    url: URI.system + "/api/tenant/featureSet",
    method: "post",
    data: d,
  });
};
// 查询mQTT主题
export const getDevicesByIds = (d = {}) => {
  return http({
    url: URI.system + "/api/tenant/mqttTopics",
    method: "get",
    params: d,
  });
};
//设备类型管理 新建主题
export const saveMqttTopic = (d = {}) => {
  return http({
    url: URI.system + "/api/tenant/mqttTopic",
    method: "post",
    data: d,
  });
};
//查询所有的协议类型
export const findProtocols = () => {
  return http({
    url: URI.system + "api/findAllProtocols",
    method: "get",
  });
};

// 查询设备参数配置列表
export const getDeviceTypeNode = (d = {}) => {
  return http({
    url: URI.system + "api/deviceTypeNode/queryPageList",
    method: "get",
    params: d,
  });
};

// 新增设备参数
export const addDeviceTypeNode = (data) => {
  return http({
    url: URI.system + "api/deviceTypeNode/addDeviceTypeNode",
    method: "post",
    data,
  });
};

// 修改设备参数
export const updateDeviceTypeNode = (data) => {
  return http({
    url: URI.system + "api/deviceTypeNode/updateDeviceTypeNode",
    method: "post",
    data,
  });
};

// 删除设备参数
export const delDeviceTypeNode = (id) => {
  return http({
    url: URI.system + "api/deviceTypeNode/deleteDeviceTypeNode",
    method: "delete",
    params: { id: id },
  });
};

// 获取参数分类列表数据
export const getClassDataList = (d = {pageNum:1,pageSize:99999,typeCode: "nodeClass"}) => {
  return http({
    url: URI.system + "api/systemDict/queryPageList",
    method: "get",
    params: d,
  });
};


// 新增参数分类数据
export const addParamClass = (data) => {
  return http({
    url: URI.system + "api/systemDict/add",
    method: "post",
    data,
  });
};

// 编辑参数分类数据
export const updateParamClass = (data) => {
  return http({
    url: URI.system + "api/systemDict/update",
    method: "post",
    data,
  });
};

// 删除参数分类数据
export const delParamClass = (id) => {
  return http({
    url: URI.system + "api/systemDict/deleteById",
    method: "delete",
    params: {id: id},
  });
};
